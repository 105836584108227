import {acceptHMRUpdate, defineStore} from 'pinia';

export const useGroupsStore = defineStore('groups', {

  state: () => ({
    groups: null,
    groupUsers: null,
    allConnections: null
  }),

  actions: {

    async getGroups() {
      if (!this.groups?.length) {
        this.groups = await useGroupsApi().fetchGroups();
      }

      return this.groups;
    },


    async getGroupUsersMgmtData(group) {

      const [groupUsers, allConnections] = await Promise.all([
        group.users_count ? useGroupsApi().fetchGroupUsers(group) : [],
        !this.allConnections?.length ? useUserApi().fetchAllConnections() : null
      ]);

      this.allConnections = allConnections || this.allConnections;
      this.groupUsers = this.allConnections.filter(c => groupUsers.map(u => u.id).includes(c.id));

      return {
        allConnections: this.allConnections,
        groupUsers: this.groupUsers
      }
    },


    async createGroup(group) {
      const newGroup = await useGroupsApi().createGroup(group);
      this.groups.push(newGroup);
      return newGroup;
    },


    async updateGroup(group) {
      const updatedGroup = await useGroupsApi().updateGroup(group);
      const updateIndex = this.groups?.findIndex(item => item.id === group.id);

      if (updateIndex > -1) {
        this.groups.splice(updateIndex, 1, updatedGroup);
      }

      return updatedGroup;
    },


    async deleteGroup(groupId) {
      await useGroupsApi().deleteGroup(groupId);

      this.groups.forEach((group, i) => {
        if (group.id === groupId) {
          this.groups.splice(i, 1);
        }
      });
    },


    async saveGroupUsers({group, userIds}) {
      await useGroupsApi().saveGroupUsers({group, userIds})

      const toUpdate = this.groups?.find(item => item.id === group.id);
      toUpdate.users_count = userIds.length;
      return true;
    },

    async saveGroupAlbums({group, albumIds}) {
      await useGroupsApi().overwriteGroupAlbums({group, albumIds})

      const toUpdate = this.groups?.find(item => item.id === group.id);
      toUpdate.albums_count = albumIds.length;
      return true;
    },


    clearGroupUsers() {
      this.groupUsers = null;
    },


    clearAllConnections() {
      this.allConnections = null
    }

  },

  getters: {
    groupsCount: state => state.groups?.length,
    groupsAlpha: state => [...state.groups].sort((a, b) => a.name.localeCompare(b.name)),
    groupsAlphaReverse: state => [...state.groups].sort((a, b) => a.name.localeCompare(b.name)).reverse(),
    groupsMostMembers: state => [...state.groups].sort((a, b) => b.users_count - a.users_count),
    groupsMostAlbums: state => [...state.groups].sort((a, b) => b.albums_count - a.albums_count)
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGroupsStore, import.meta.hot));
}
