<template>
  <div class="border border-charcoal-200 bg-white flex flex-col">
    <core-search-input
      class="flex -m-px mb-0"
      v-if="!error && !showOnlySelected"
      placeholder="Search Albums"
      @search="updateSearchQuery"
      @clear="updateSearchQuery({q: ''})"
      :loading="pending"
    />
    <core-contextual-loading-box v-if="pending" key="loading" :loading-text="`Loading Albums`" size="sm" />
    <core-contextual-error-box v-else-if="error" key="errored" :action="refresh" size="sm" />

    <template v-else>

      <core-add-new-to-list
        label="New Album"
        placeholder="Enter Album Name..."
        :add-fn="createAlbum"
        @error="addNewAlbumError"
      />

      <div class="flex-1 overflow-y-auto scrollbar-light" ref="scrollEl" :class="isEmpty ? 'bg-white flex-center' : ''">
        <template v-if="isEmpty">
          <div class="p-5 text-center">
            <p class="font-serif text-xl">No Albums Available</p>
            <p class="text-sm text-charcoal-400">Albums are a great way to share your content with specific people. Create an Album to get started.</p>
          </div>
        </template>
        <core-list-multi-selector
          v-else
          v-model="selectedAlbums"
          :options="showOnlySelected ? selectedAlbums.sort((a, b) => a.name.localeCompare(b.name)) : albums"
          :has-more-options="hasMore"
          :is-loading-more-options="isGettingMore"
          @load-more-options="getMore"
          :scroll-element="scrollEl"
        >
          <template #option-item="{option: album}">
            <div class="flex items-center gap-2 ">
              <album-avatar :album="album" size="xs" />
              <span class="flex-1 text-sm break-long-string">{{album.name}}</span>
            </div>
          </template>
        </core-list-multi-selector>
      </div>

    </template>
  </div>
</template>

<script setup>
  import {makeAlbumsStore} from '~/stores/albums.js';

  const props = defineProps({
    showOnlySelected: Boolean
  });

  const scrollEl = ref();
  const albumStore = makeAlbumsStore('album-selection-modal')();
  const selectedAlbums = defineModel('selectedAlbums');

  const {albums, hasMore} = storeToRefs(albumStore);
  const isEmpty = computed(() => !albums?.value?.length);

  const searchTerm = ref('');
  function updateSearchQuery({q}) {
    searchTerm.value = q;
  }

  const isGettingMore = ref(false);
  async function getMore() {
    if (!hasMore.value || isGettingMore.value) {
      return;
    }

    try {
      isGettingMore.value = true;
      await albumStore.getAlbums({nextPage: true});
    } catch (e) {
      useErrorToast().add();
    } finally {
      isGettingMore.value = false;
    }
  }

  const {refresh, pending, error} = await useAsyncData(
    'group-album-modal-data',
    () => albumStore.getAlbums({search: searchTerm.value}),
    {
      watch: [searchTerm]
    }
  );

  async function createAlbum(name) {
    const newAlbum = await albumStore.createAlbum({payload: {name}});
    albums.value.unshift(newAlbum);
    selectedAlbums.value = [newAlbum, ...selectedAlbums.value];
  }

  function addNewAlbumError() {
    useErrorToast().add();
  }
</script>
