<template>
  <div>
    <core-modal-header>Albums Shared with {{group.name}}</core-modal-header>

    <core-modal-body>
      <p class="flex mb-1">
        <span v-if="selectedAlbums" class="flex-1 text-xs">{{showOnlySelected ? `Viewing ${selectedAlbums.length} Selected Albums` : 'Viewing All Albums'}}</span>
        <u-button
          v-if="selectedAlbums?.length"
          size="xs"
          :padded="false"
          variant="link"
          @click="showOnlySelected = !showOnlySelected"
          :label="showOnlySelected ? 'Show All Albums' : `Show Selected Only (${selectedAlbums.length})`"
        />
      </p>

     <album-selection-list
        class="h-80"
        v-model:selected-albums="selectedAlbums"
        :show-only-selected="showOnlySelected"
      />

    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" @click="modal.close()">Cancel</u-button>
      <u-button @click="save" :loading="isSaving">Save</u-button>
    </core-modal-footer>
  </div>
</template>

<script setup>
  const props = defineProps({
    group: Object,
    modal: Object
  });
  const emit = defineEmits(['saved']);
  const {fetchGroupAlbums} = useGroupsApi();

  const {saveGroupAlbums} = useGroupsStore();

  const {data: selectedAlbums} = await useLazyAsyncData(
    'group-albums',
    () => fetchGroupAlbums(props.group.id)
  );

  const showOnlySelected = ref(false);
  const isSaving = ref(false);

  async function save() {
    isSaving.value = true;

    try {
      await saveGroupAlbums({
        group: props.group,
        albumIds: selectedAlbums.value.map(a => a.id)
      });

      useSuccessToast().add({
        description:'Group albums have been updated.'
      });
      emit('saved');
      props.modal.close();
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }
</script>
