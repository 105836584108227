<template>
  <u-form @submit="submit" :schema="schema" :state="state" :validate-on="['submit']">
    <core-modal-header v-if="modal">{{group ? 'Edit Group' : 'Add New Group'}}</core-modal-header>

    <component :is="modal ? CoreModalBody : 'div'">
      <u-form-group label="Group Name" name="name" class="mb-4" required>
        <u-input autofocus v-model="state.name" placeholder="Name your group" />
      </u-form-group>

      <u-form-group label="Group Description" name="description">
        <template #hint>
          <core-input-length-counter v-model="state.description" :limit="DESC_MAX_LENGTH" :display-at="DESC_MAX_LENGTH * 0.80" />
        </template>
        <u-textarea v-model="state.description" :rows="7" :maxlength="DESC_MAX_LENGTH" placeholder="Add a description" />
      </u-form-group>
    </component>

    <component :is="modal ? CoreModalFooter : 'div'" :border="false" :class="modal ? '' : 'pt-6 text-right'">
      <u-button color="charcoal" variant="ghost" v-if="modal" @click="modal.close()">Cancel</u-button>
      <u-button type="submit" :loading="isSaving">{{group ? 'Update Group' : 'Create Group'}}</u-button>
    </component>

  </u-form>

</template>

<script setup>
  import {object, string} from 'yup';
  import {CoreModalBody, CoreModalFooter} from '#components';

  const {createGroup, updateGroup} = useGroupsStore();
  const {manageGroupUsers, manageGroupAlbums} = useGroupsActions()

  const NAME_MAX_LENGTH = 75;
  const DESC_MAX_LENGTH = 500;

  const isSaving = ref(false);

  const props = defineProps({
    group: Object,
    modal: Object
  });

  const emit = defineEmits(['on-success'])

  const schema = object({
    name: string().required('A group name is required.').max(NAME_MAX_LENGTH, `Max length cannot exceed ${NAME_MAX_LENGTH} characters.`),
    description: string().nullable(true)
  });

  const state = reactive({
    name: props.group?.name,
    description: props.group?.description,
    id: props.group?.id
  });

  async function submit() {
    try {
      isSaving.value = true;
      const group = await (props.group ? updateGroup : createGroup)(state);
      emit('on-success', group);
      useSuccessToast().add({
        id: 'group-add-edit',
        title: `Group ${props.group ? 'Updated' : 'Created'}`,
        description: `The Group <strong>${state.name}</strong> has been ${props.group ? 'updated' : 'created'}.`,
        actions: props.group ? [] : [
          {
            label: 'Add Members',
            click: () => manageGroupUsers(group)
          },
          {
            label: 'Add Albums',
            click: () => manageGroupAlbums(group)
          }
        ]
      });
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }
</script>
