<template>
  <div>

    <core-modal-header>Manage Members in {{group.name}}</core-modal-header>

    <core-modal-body>

      <p class="flex mb-1">
        <span v-if="groupUsers" class="flex-1 text-xs">{{showOnlyMembers ? `Viewing ${groupUsers.length} Members` : 'Viewing All Connections'}}</span>
        <u-button v-if="groupUsers?.length" size="xs" :padded="false" variant="link" @click="showOnlyMembers = !showOnlyMembers">{{showOnlyMembers ? 'Show All Connections' : `Show Members Only (${groupUsers.length})`}}</u-button>
      </p>
      <div class="h-80 overflow-y-auto border-2 border-charcoal-200 scrollbar-light" :class="pending || error || !hasConnections ? 'bg-white flex-center py-8' : ''">
        <core-contextual-loading-box v-if="pending" key="loading" loading-text="Loading Connections" size="sm" />

        <core-contextual-error-box v-else-if="error" key="errored" :action="refresh" :show-background="false" size="sm" />

        <div v-else-if="!hasConnections" class="text-center bg-white py-6">
          <p class="font-serif text-xl">You haven't added Connections.</p>
          <p class="text-sm text-charcoal-400 mb-4">Invite & find friends and family in FOREVER and start sharing today!</p>
          <u-button :icon="COMMON_ICONS.add" size="sm" @click="navigateTo('/connections')">Add Connections</u-button>
        </div>

        <core-list-multi-selector v-else v-model="groupUsers" :options="showOnlyMembers ? groupUsers.sort((a, b) => a.name.localeCompare(b.name)) : allConnections.sort((a, b) => a.name.localeCompare(b.name))">
          <template #option-item="props">
            <div class="flex items-center gap-4">
              <user-avatar :user="props.option" size="sm" />
              <p class="flex-1 text-sm font-semibold leading-none text-left">{{props.option.name}}</p>
            </div>
          </template>
        </core-list-multi-selector>
      </div>


    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" @click="modal.close()">Cancel</u-button>
      <u-button v-if="hasConnections" @click="save" :loading="isSaving">Save</u-button>
    </core-modal-footer>

  </div>
</template>

<script setup>
  import {storeToRefs} from 'pinia';

  const props = defineProps({
    group: Object,
    modal: Object
  });

  const emit = defineEmits(['on-save']);

  const groupsStore = useGroupsStore();
  const {allConnections, groupUsers} = storeToRefs(groupsStore);
  const hasConnections = computed(() => allConnections?.value?.length);



  const showOnlyMembers = ref(false);

  let isSaving = ref(false);

  const {refresh, pending, error} = await useLazyAsyncData(
    'group-user-modal-data',
    () => groupsStore.getGroupUsersMgmtData(props.group)
  );

  async function save() {
    isSaving.value = true;

    try {
      await groupsStore.saveGroupUsers({
        group: props.group,
        userIds: groupUsers.value.map(u => u.id)
      })

      props.modal.close();

      useSuccessToast().add({
        id: 'group-member-update',
        title: 'Group Members Updated',
        description: `The Group member updates have been saved.`,
      });
      //emit('on-save')
    } catch (err) {
      useErrorToast().add({
        id: 'group-delete-error',
        description: 'We were unable to save group members at this time.'
      });
    } finally {
      isSaving.value = false;
    }
  }

  //switch back to viewing all connection when last user is unchecked
  watch(() => groupUsers?.value?.length, newValue => {
    if (!newValue) {
      showOnlyMembers.value = false;
    }
  });

  onBeforeUnmount(() => {
    groupsStore.clearGroupUsers();
  })
</script>
