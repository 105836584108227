import {
  FileUpdateContextThumbnailModal,
  GroupsEditForm,
  GroupsManageGroupAlbumsModal,
  GroupsManageGroupUsersModal,
  GroupsManageUserGroupsModal
} from '#components';

export function useGroupsActions() {

  function createEditGroup(group) {
    return new Promise(resolve => {
      useFModal({
        id: 'group-edit',
        clickToClose: false,
        component: GroupsEditForm,
        props: {group},
        eventHandlers: {
          onSuccess(resGroup) {
            this.close();
            resolve(resGroup);
          }
        }
      }).open();
    });
  }

  function deleteGroup(group) {
    return new Promise(resolve => {
      useConfirmModal({
        heading: `Delete the Group <strong>${group.name}</strong>?`,
        message: 'This will revoke all group permissions.',
        confirmMethod: async () => {
          try {
            await useGroupsStore().deleteGroup(group.id);
            useSuccessToast().add({
              title: 'Group Deleted',
              description: `The Group <strong>${group.name}</strong> has been deleted.`
            });
          } catch (e) {
            useErrorToast().add();
          }
        },
        onConfirm: () => resolve()
      }).open()
    });
  }

  function manageGroupAlbums(group) {
    return new Promise(resolve => {
      useFModal({
        id: 'manage-group-albums',
        clickToClose: false,
        component: GroupsManageGroupAlbumsModal,
        props: {group},
        eventHandlers: {
          onSuccess() {
            resolve();
          }
        }
      }).open();
    });
  }

  function manageGroupUsers(group) {
    return new Promise(resolve => {
      useFModal({
        id: 'manage-group-users',
        clickToClose: false,
        component: GroupsManageGroupUsersModal,
        props: {group},
        eventHandlers: {
          onSuccess() {
            resolve();
          }
        }
      }).open();
    });
  }

  function manageUserGroups({user}) {
    return new Promise(resolve => {
      useFModal({
        id: 'manage-user-groups',
        component: GroupsManageUserGroupsModal,
        props: {user},
        eventHandlers: {
          async saved() {
            await useUsersStore().refreshConnection({userIdOrSlug: user.id});
            resolve();
          }
        }
      }).open();
    });
  }

  function editThumbnail(group) {
    const modal = useFModal({
      id: 'edit-album-thumbnail',
      component: FileUpdateContextThumbnailModal,
      size: 'fullscreen',
      showClose: false,
      props: {
        name: group.name
      },
      eventHandlers: {
        async complete(file) {
          try {
            await useGroupsStore().updateGroup({
              id: group.id,
              forever_file_id: file.id
            });
            modal.close();
          } catch (e) {
            useErrorToast().add();
          }
        }
      }
    });

    modal.open();
  }

  return {
    createEditGroup,
    deleteGroup,
    manageGroupAlbums,
    manageGroupUsers,
    manageUserGroups,
    editThumbnail
  }
}
