<template>
  <div>
    <core-modal-header>Manage Groups of {{user.name}}</core-modal-header>

    <core-modal-body>
      <p class="flex mb-1">
        <span v-if="selectedGroups" class="flex-1 text-xs">{{showOnlyGroupMembership ? `Viewing ${selectedGroups.length} Groups` : 'Viewing All Connections'}}</span>
        <u-button
          v-if="selectedGroups?.length"
          size="xs"
          :padded="false"
          variant="link"
          @click="showOnlyGroupMembership = !showOnlyGroupMembership"
          :label="showOnlyGroupMembership ? 'Show All Groups' : `Show Selected Groups Only (${selectedGroups.length})`"
        />
      </p>
      <div>
        <groups-selection-list
          class="h-80"
          v-model:selected-groups="selectedGroups"
          :show-only-selected="showOnlyGroupMembership"
        />
      </div>
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" @click="modal.close()">Cancel</u-button>
      <u-button @click="save" :loading="isSaving">Save</u-button>
    </core-modal-footer>

  </div>
</template>

<script setup>
  const props = defineProps({
    user: Object,
    modal: Object
  });

  const emit = defineEmits(['saved']);
  const {overwriteUserGroups, fetchUserGroups} = useGroupsApi();
  const showOnlyGroupMembership = ref(false);
  const isSaving = ref(false);

  const {data: selectedGroups} = await useAsyncData(
    'user-permission-groups',
    () => fetchUserGroups(props.user.id)
  );

  async function save() {
    isSaving.value = true;

    try {
      await overwriteUserGroups({
        user: props.user,
        groups: selectedGroups.value
      });

      useSuccessToast().add({
        description:'User groups have been updated.'
      });
      emit('saved');
      props.modal.close();
    } catch (err) {
      useErrorToast().add({
        description: 'We were unable to save group memberships at this time.'
      });
    } finally {
      isSaving.value = false;
    }
  }
</script>
